import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useJsApiLoader,
  Marker,
  GoogleMap,
  LoadScript,
  DirectionsService,
  DirectionsRenderer,
  Polyline,
  BicyclingLayer,
} from "@react-google-maps/api";
import { socket } from "../../utils/socket";
import { useParams } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import LoadingBox from "./LoadingBox";

// const defaultLocation = { lat: 45.516, lng: -73.56 };
const defaultLocation = { lat: 24.7913957, lng: 85.0002336 };
const libs = ["places"];
const API_KEY = "AIzaSyDBOVm6aEh2ANPna0JwaC8FRl9pYMeAMA0";

export default function LiveMap({ source, dest }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: libs,
  });
  console.log({ source, dest });
  const { id } = useParams();

  const [to, setTo] = useState();
  const [from, setFrom] = useState();
  const [response, setResponse] = useState(null);
  const [center, setCenter] = useState(defaultLocation);

  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);

  useEffect(() => {
    if (source && dest) {
      // setFrom({ lat: pathlist[0].location.latitude, lng: pathlist[0].location.longitude });
      // setTo({ lat: pathlist[pathlist.length - 1].location.latitude, lng: pathlist[pathlist.length - 1].location.longitude });
      setTo({ lat: dest.lat, lng: dest.long });
      setFrom({ lat: source.lat, lng: source.long });
      setCenter({ lat: source.lat, lng: source.long });
    }
  }, [source?.lat, dest?.lat]);

  useEffect(() => {
    console.log("IN USE-EFFECT");
    const newLocation = (loc) => {
      console.log({ loc });
      const [lat, lng, _] = loc.split(",");
      setCenter({
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      });
    };

    function onConnect() {
      console.log("CONNECTED");
      setIsConnected(true);
    }

    function onDisconnect(reason) {
      console.log("DIS-CONNECTED", reason);
      setIsConnected(false);
    }

    function onFooEvent(value) {
      setFooEvents((previous) => [...previous, value]);
    }

    console.log({ socket });
    socket.emit("join-room", id, (res) => {
      if (res.status === "OK") {
        console.log("successfully connected...");
      }
    });

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("new-location", newLocation);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("foo", onFooEvent);
    };
  }, [socket]);

  console.log({ center });
  const directionsCallback = (res) => {
    if (res !== null && res.status === "OK") {
      setResponse(res);
    }
  };

  return isLoaded ? (
    <div className="full-box" style={{ border: "1px solid #aaa" }}>
      {/* <LoadScript libraries={libs} googleMapsApiKey="AIzaSyB_I6ZXsXxpyAndkWBRWUJEMxsqKWVhGpo"> // sahil */}
      {/* <LoadScript libraries={libs} googleMapsApiKey={API_KEY}> */}
      <GoogleMap
        id="sample-map"
        center={center}
        zoom={10}
        // heading={pathlist[pathlist.length - 1].location}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        onLoad={(map) => {
          mapRef.current = map;
        }}
        options={
          {
            // zoomControl: false,
            // streetViewControl: false,
            // mapTypeControl: false,
            // fullscreenControl: false,
          }
        }
      >
        {/* {response !== null && (
          <DirectionsRenderer
            options={{
              directions: response,
            }}
          />
        )}

        {from && to && response === null && <DirectionsService
          options={{
            origin: from,
            destination: to,
            travelMode: 'DRIVING',
          }}
          callback={(res) => {
            // console.log({ res })
            if (res && res.status === 'OK') {
              setResponse(res);
            }
          }}
        />} */}

        <DirectionsService
          options={{
            destination: to,
            origin: from,
            travelMode: "DRIVING",
          }}
          callback={directionsCallback}
        />
        {response && <DirectionsRenderer directions={response} />}

        {center && <>
          <Marker position={center} onLoad={(marker) => markerRef.current = marker}
            icon={{ url: "/truck.png" }}
          />
          <Polyline
            path={[from, center]}
            options={{ strokeColor: '#b0b0b0', strokeOpacity: 0.8, strokeWeight: 4 }}
          />
        </>} 


        {/* {response && (
          <DirectionsRenderer
            directions={response}
            options={{
              polylineOptions: {
                strokeColor: "#007aff", // Default color
                strokeOpacity: 0.8,
                strokeWeight: 4,
              },
              // Adjust polyline options based on covered distance
              suppressPolylines: true, // Suppress default polyline
              suppressMarkers: true, // Suppress default markers
            }}
          />
        )} */}

        {/* {from && to && <>
          <Marker position={from} icon={{ url: "/pickup.png" }} />
          <Marker position={to} icon={{ url: "/drop.png" }} />

          <Polyline
            path={[center, to]}
            options={{
              strokeColor: '#007aff', strokeOpacity: 0.8, strokeWeight: 4
            }}
          />
        </>}
        <BicyclingLayer />
        {center && <>
          <Marker position={center} onLoad={(marker) => markerRef.current = marker}
            icon={{ url: "/truck.png" }}
          />
          <Polyline
            path={[from, center]}
            options={{ strokeColor: '#b0b0b0', strokeOpacity: 0.8, strokeWeight: 4 }}
          />
        </>} 
        */}
      </GoogleMap>
      {/* </LoadScript> */}
    </div>
  ) : (
    <LoadingBox />
  );
}
