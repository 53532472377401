import React, { useContext, useEffect, useReducer, useState } from "react";
import { Store } from "../../states/store";
import { clearErrors } from "../../states/actions";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import {
  MessageBox,
  useTitle,
  MotionDiv,
  ViewButton,
  DeleteButton,
  CustomSkeleton,
} from "../../components";
import reducer from "./state/reducer";
import { getAll, del } from "./state/action";
import { toastOptions } from "../../utils/error";
import { Card, Form, Table } from "react-bootstrap";
import { getAll as getDriver } from "../user/state/action";
import driverReducer from "../user/state/reducer";

export default function TimeTracker() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { token } = state;
  const [currentDriver, setCurrentDriver] = useState("");

  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

  const [{ loading, error, logs }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });
  console.log({ logs });
  const [{ users, userCount }, userDispatch] = useReducer(driverReducer, {
    loading: true,
    error: "",
  });
  const deleteUser = async (id) => {
    await del(dispatch, token, id);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDriver(userDispatch, token, "", "", "");
    };
    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      await getAll(dispatch, token, date, currentDriver);
    };
    fetchData();
  }, [token, date, currentDriver]);

  useEffect(() => {
    if (error) {
      toast.error(error, toastOptions);
      clearErrors(dispatch);
    }
  }, [error]);

  const column = ["S.No", "Driver", "Check In", "Check Out", "Time", "Actions"];

  useTitle("Time Tracker");
  return (
    <MotionDiv>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h3 className="mb-0">Time Tracker</h3>
              <div className="d-flex gap-4">
                <Form.Control
                  aria-label="Search Input"
                  type="date"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
                <select
                  className="form-select"
                  value={currentDriver}
                  onChange={(e) => {
                    setCurrentDriver(e.target.value);
                  }}
                  aria-label="Default select example"
                >
                  <option key="blankChoice" value="">
                    All
                  </option>
                  {users &&
                    users.map((user, index) => {
                      return (
                        <option value={user._id} key={index}>
                          {user.firstname + " " + user.lastname}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  {column.map((col) => (
                    <th key={col}>{col}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <CustomSkeleton resultPerPage={10} column={6} />
                ) : (
                  logs &&
                  logs.length > 0 &&
                  (currentDriver
                    ? logs.filter((log) => log.user._id == currentDriver)
                    : logs
                  ).map((log, i) => (
                    <tr key={log._id} className="odd">
                      <td className="text-center">{i + 1}</td>
                      <td>{`${log?.user?.firstname} ${log?.user?.lastname}`}</td>
                      <td>{log.start}</td>
                      <td>{log.end}</td>
                      <td>{log.time}</td>
                      <td>
                        <ViewButton
                          onClick={() =>
                            navigate(
                              `/admin/time-tracker/dId/${log?.user?._id}`
                            )
                          }
                        />
                        <DeleteButton onClick={() => deleteUser(log._id)} />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
      <ToastContainer />
    </MotionDiv>
  );
}
