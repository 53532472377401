import React, { useContext, useReducer, useState } from "react";
import { Store } from "../../states/store";

import { ToastContainer, toast } from "react-toastify";
import reducer from "./state/reducer";
// import { create } from "./state/action";
import {
  useTitle,
  AddForm,
  UploadFileComp,
  RadioInput,
} from "../../components";
import { getError, toastOptions } from "../../utils/error";
import axiosInstance from "../../utils/axiosUtil";
import { useNavigate } from "react-router-dom";

export default function AddUser() {
  const { state } = useContext(Store);
  const { token } = state;
  const navigate = useNavigate();

  const [{ loading, error, success }, dispatch] = useReducer(reducer, {
    loading: false,
    error: "",
  });

  const userData = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    country_code: "",
    mobile: "",
  };
  const userAttr = [
    {
      type: "text",
      col: 12,
      props: {
        label: "First Name",
        name: "first_name",
        required: true,
      },
    },
    {
      type: "text",
      col: 12,
      props: {
        label: "Last Name",
        name: "last_name",
        // required: true,
      },
    },
    {
      type: "email",
      col: 12,
      props: {
        type: "email",
        label: "Email",
        name: "email",
        required: true,
      },
    },
    {
      type: "password",
      col: 12,
      props: {
        type: "password",
        label: "Password",
        name: "password",
        minLength: 8,
        required: true,
      },
    },
    {
      type: "number",
      col: 12,
      props: {
        type: "text",
        label: "Country Code",
        name: "country_code",
        required: true,
        maxLength: 4,
      },
    },
    {
      type: "number",
      col: 12,
      props: {
        type: "number",
        label: "Mobile",
        name: "mobile",
        minLength: 10,
        maxLength: 10,
        required: true,
      },
    },
    {
      type: "file",
      col: 12,
      props: {
        type: "file",
        label: "Upload Profile",
        name: "profile",
        accept: "image/*",
        //   required: true,
      },
    },
  ];
  const [info, setInfo] = useState(userData);

  const resetForm = () => {
    setInfo(userData);
  };
  const [profile, setProfile] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(info);
    const isValidCode = /^\+\d{1,3}$/.test(info?.country_code);
    if (!isValidCode) {
      toast.warning("Please enter valid country code");
      return;
    }
    const formData = new FormData();
    formData.append("firstname", info.first_name);
    formData.append("lastname", info.last_name);
    formData.append("email", info.email);
    formData.append("password", info.password);
    formData.append("country_code", info.country_code);
    formData.append("mobile_no", info.mobile);
    profile && formData.append("image", profile);
    try {
      const { data } = await axiosInstance.post(
        "/api/admin/create-driver",
        formData,
        {
          headers: { Authorization: token },
        }
      );
      console.log(data);
      if (data.success) {
        toast.success("Driver Created Successfully",{position:"bottom-right"});
        setTimeout(()=>{
          navigate("/admin/drivers");
        },1500)
      }
    } catch (error) {
        toast.error(getError(error),{position:"bottom-right"});
    }
    
  };

  useTitle("Create Driver");
  return (
    <AddForm
      title="Add Driver"
      data={info}
      setProfile={setProfile}
      setData={setInfo}
      inputFieldProps={userAttr}
      submitHandler={submitHandler}
      target="/admin/drivers"
      successMessage="Driver Created Successfully!"
      reducerProps={{ loading, error, success, dispatch }}
    >
      <ToastContainer />
    </AddForm>
  );
}
