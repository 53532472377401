import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "http://localhost:5000", // localhost
  // baseURL: "https://jeff.adaptable.app/" // hosted
  // baseURL: "https://trucking-backend.adaptable.app"
  baseURL: "https://api.barrtires.ca",
});

export default axiosInstance;
