import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Store } from "../states/store";
// icons
import { MdContactPhone } from "react-icons/md";
import { BsShieldFillPlus } from "react-icons/bs";
import { FaArrowCircleRight } from "react-icons/fa";
import { BiSolidShieldMinus, BiSolidShieldX } from "react-icons/bi";
import { HiShieldCheck, HiShieldExclamation } from "react-icons/hi";

import Skeleton from "react-loading-skeleton";
import axiosInstance from "../utils/axiosUtil";
import { getError } from "../utils/error";
import { MotionDiv, MessageBox, CountUp } from "../components";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        summary: action.payload,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const card = [
  {
    key: "awaited",
    bg: "primary",
    icon: <BsShieldFillPlus />,
    text: "Awaited Warranties",
    url: "/admin/warranty/?status=AWAITED",
  },
  {
    key: "passed",
    bg: "success-1",
    icon: <HiShieldCheck />,
    text: "Passed Warranties",
    url: "/admin/warranty/?status=PASSED",
  },
  {
    key: "active",
    bg: "success",
    icon: <HiShieldCheck />,
    text: "Active Warranties",
    url: "/admin/warranty/?status=ACTIVE",
  },
  {
    key: "rejected",
    bg: "danger",
    icon: <BiSolidShieldX />,
    text: "Rejected Warranties",
    url: "/admin/warranty/?status=REJECTED",
  },
  {
    key: "toExpired",
    bg: "warning",
    icon: <HiShieldExclamation />,
    text: "Expiring Warranties In 30 Days",
    url: "/admin/warranty/?status=TO-BE-EXPIRED",
  },
  {
    key: "expired",
    bg: "danger",
    icon: <BiSolidShieldMinus />,
    text: "Expired Warranties",
    url: "/admin/warranty/?status=EXPIRED",
  },
  {
    key: "enquiry",
    bg: "info",
    icon: <MdContactPhone />,
    text: "Enquiry",
    url: "/admin/enquiry",
  },
];

const ViewCard = ({ loading, data, bg, icon, text, url }) => {
  return (
    <div>
      {loading ? (
        <Skeleton count={5} />
      ) : (
        <div className={`small-box bg-${bg}`}>
          {/* <div className="inner p-sm-1 p-md-2 p-lg-3"> */}
          <div className="inner">
            <CountUp start={0} end={data} duration={2} />
            {/* <h1>
              {data && data[0] ? data[0].total : 0}
            </h1> */}
            <h5
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {text}
            </h5>
          </div>
          <div className="icon">{icon}</div>
          <Link to={url} className="small-box-footer">
            More info {<FaArrowCircleRight />}
          </Link>
        </div>
      )}
    </div>
  );
};

export default function Dashboard() {
  const [{ loading, summary, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });
  const [load, setLoad] = useState(true);
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { token } = state;
  const [arra, setArr] = useState([]);
  const dummyArray = [1, 2, 3, 4];
  const arr = ["bg-info", "bg-success", "bg-primary", "bg-danger"];
  const redirectArray = ["/drivers", "/trips", "/trucks", "/locations"];

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await axiosInstance.get(
          `/api/admin/get-dashboard-data`,
          {
            headers: { Authorization: token },
          }
        );
        console.log({ data });

        dispatch({ type: "FETCH_SUCCESS", payload: data });
        setArr(data.dashBoardData);
        setLoad(false);
      } catch (err) {
        setLoad(false);
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
        toast.error(getError(err), {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    })();
  }, [token]);

  return (
    <MotionDiv>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Container>
          <h3>{load ? <Skeleton /> : "Dashboard"}</h3>
          <hr />
          <div
            className={`d-flex ${loading && " items-center"} flex-wrap gap-3`}
          >
            {load
              ? dummyArray.map((data, index) => {
                  return (
                    <div key={index} className="dashboard_cards">
                      <Skeleton height={200} />
                    </div>
                  );
                })
              : arra.map((data, index) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(`/admin${redirectArray[index]}`);
                      }}
                      key={index}
                      className={`dashboard_cards ${arr[index]} card d-flex justify-content-center align-items-center gap-1 flex-column`}
                    >
                      <h2 style={{ color: "#f3f3f3" }}>
                        {/* <CountUp
                          end={Number(data.value)}
                          useGrouping={false}
                          duration={"2"}
                          style={{ color: "#f3f3f3" }}
                        /> */}
                        {data.value}
                      </h2>
                      <h3 style={{ color: "#f3f3f3", fontSize: "16px" }}>
                        {data.key}
                      </h3>
                    </div>
                  );
                })}
          </div>
          <ToastContainer />
        </Container>
      )}
    </MotionDiv>
  );
}
