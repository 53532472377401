import React, { useContext, useEffect, useReducer, useState } from "react";
import  reducer  from "../content/state/reducer";
import axiosInstance from "../../utils/axiosUtil";
import { Store } from "../../states/store";
import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { getContent } from "../content/state/action";

export default function TermsAndCondition() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { token } = state;

  const [{ loading, loadingUpdate, error, content, success }, dispatch] =
    useReducer(reducer, {
      loading: true,
      loadingUpdate: false,
      error: "",
    });

    console.log(content)

    useEffect(() => {
      const fetchData = async () => {
        await getContent(dispatch, token);
      }
      fetchData();
    }, [token,loadingUpdate]);

  
  // const [{ loading, error }, dispatch] = useReducer(reducer, {
  //   loading: false,
  //   error: "",
  // });

  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container>
        {<div>
          <div className="d-flex mt-2 justify-content-between">
            <div>
              <h5>Terms & Condition Content</h5>
            </div>
           
          </div>
          <div dangerouslySetInnerHTML={{ __html: content&&content?.tc }} />
        </div>}
        
      </Container>
    </motion.div>
  );
}
